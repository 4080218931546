let accordion;
/*
   Simple accordion pattern example
*/
'use strict';

Array.prototype.slice.call(document.querySelectorAll('.accordion')).forEach(function (accordion) {

  var allowMultiple = accordion.hasAttribute('data-allow-multiple');
  var allowToggle = (allowMultiple) ? allowMultiple : accordion.hasAttribute('data-allow-toggle');

  var triggers = Array.prototype.slice.call(accordion.querySelectorAll('.accordion__trigger'));
  var panels = Array.prototype.slice.call(accordion.querySelectorAll('.accordion__panel'));

  accordion.addEventListener('click', function (event) {
    var target = event.target;

    if (target.classList.contains('accordion__trigger')) {
      var isExpanded = target.getAttribute('aria-expanded') == 'true';
      var active = accordion.querySelector('[aria-expanded="true"]');

      if (!allowMultiple && active && active !== target) {
        active.setAttribute('aria-expanded', 'false');
        document.getElementById(active.getAttribute('aria-controls')).setAttribute('hidden', '');

        if (!allowToggle) {
          active.removeAttribute('aria-disabled');
        }
      }

      if (!isExpanded) {
        target.setAttribute('aria-expanded', 'true');
        document.getElementById(target.getAttribute('aria-controls')).removeAttribute('hidden');

        if (!allowToggle) {
          target.setAttribute('aria-disabled', 'true');
        }
      }
      else if (allowToggle && isExpanded) {
        target.setAttribute('aria-expanded', 'false');
        document.getElementById(target.getAttribute('aria-controls')).setAttribute('hidden', '');
      }
      event.preventDefault();
    }
  });

  accordion.addEventListener('keydown', function (event) {
    var target = event.target;
    var key = event.which.toString();

    var isExpanded = target.getAttribute('aria-expanded') == 'true';
    var allowToggle = (allowMultiple) ? allowMultiple : accordion.hasAttribute('data-allow-toggle');

    var ctrlModifier = (event.ctrlKey && key.match(/33|34/));

    if (target.classList.contains('accordion__trigger')) {
      if (key.match(/38|40/) || ctrlModifier) {
        var index = triggers.indexOf(target);
        var direction = (key.match(/34|40/)) ? 1 : -1;
        var length = triggers.length;
        var newIndex = (index + length + direction) % length;

        triggers[newIndex].focus();
        event.preventDefault();
      }
      else if (key.match(/35|36/)) {
        switch (key) {
          case '36':
            triggers[0].focus();
            break;
          case '35':
            triggers[triggers.length - 1].focus();
            break;
        }
        event.preventDefault();
      }
    }
  });

  [].forEach.call(accordion.querySelectorAll('.accordion__trigger'), function (trigger) {
    trigger.addEventListener('focus', function (event) {
      accordion.classList.add('focus');
    });

    trigger.addEventListener('blur', function (event) {
      accordion.classList.remove('focus');
    });
  });


  if (!allowToggle) {
    var expanded = accordion.querySelector('[aria-expanded="true"]');

    if (expanded) {
      expanded.setAttribute('aria-disabled', 'true');
    }
  }

});


export default accordion;
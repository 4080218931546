// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
// import Swiper JS
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

const swiper = new Swiper('.js-swiper--standard', {
  speed: 400,
  loop: true,

  modules: [Navigation, Pagination],
  // Pagination fraction numbers
  pagination: {
    el: ".swiper-pagination-fraction",
    type: "fraction",
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

});

const swiper_cards = new Swiper('.js-swiper--cards', {
  speed: 4000,
  loop: true,
  slidesPerView: 1.1,
  spaceBetween: 20,


  breakpoints: {
    320: {
      slidesPerView: 2,
      spaceBetween: 22,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 22,
    },
    992: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
    1400: {
      slidesPerView: 5,
      spaceBetween: 20,
    },
  },

  modules: [Autoplay],

  autoplay: {
    delay: 0,
  },

});

const swiper_slider = new Swiper(".rsce_cs_008", {
  slidesPerView: 1.3,
  spaceBetween: 24,
  loop: true,
  centeredSlides: true,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  scrollbar: {
    el: ".swiper-scrollbar",
    draggable: true,
  },
  breakpoints: {
    768: {
      slidesPerView: 2,
      centeredSlides: false,
    },
    992: {
      slidesPerView: 3,
      centeredSlides: false,
    },
    1200: {
      slidesPerView: 4,
      centeredSlides: false,
    }
  }
});

const image_slider = new Swiper(".js-swiper-image-slider", {
  speed: 400,
  loop: true,

  modules: [Navigation, Pagination],
  // Pagination fraction numbers
  pagination: {
    el: ".swiper-pagination-fraction",
    type: "fraction",
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

export default Swiper;
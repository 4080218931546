console.log('init');
// Remove inert attributes (set from cookiebar)

setTimeout(() => {
  console.log('start timeout');
  window.addEventListener('cookiebar_init', function (e) {
    console.log('event init');
    let elements = document.querySelectorAll(':scope body >:not(script):not(.contao-cookiebar)');
    if (elements) {
      console.log(elements);
      elements.forEach(element => {
        element.removeAttribute('inert');
      });
    }
  }, false);

}, 2000);
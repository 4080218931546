/***** rsce_cs_001 *****/
let cs001;

// encapsulate element js
cs001 = function () {
  window.addEventListener("load", function () {
    let selector = ".clac-001-scrolltop-button";
    let scrollTopButton = document.querySelector(selector);

    if (!scrollTopButton) {
      return;
    }

    // add click event
    scrollTopButton.addEventListener("click", function () {
      window.scrollTo({ top: 0, behavior: "smooth" });
    });

    // on scroll
    window.addEventListener("scroll", function () {
      let body = document.body, html = document.documentElement;
      let pageHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

      if ((pageHeight > window.innerHeight * 2) && (window.scrollY > pageHeight / 2)) {
        scrollTopButton.classList.add("active");
      } else {
        scrollTopButton.classList.remove("active");
      }
    });

  });
}

// init
cs001();

export default cs001;

// marco´s stuff :-)

// // scroll up
// const scrollUpBtn = document.querySelector(".js-scroll-top");
//
// const scrollToTop = () => {
//   const c = document.documentElement.scrollTop || document.body.scrollTop;
//   if (c > 0) {
//     window.requestAnimationFrame(scrollToTop);
//     window.scrollTo(0, c - c / 8);
//   }
// };
//
// scrollUpBtn.addEventListener("click", () => {
//   scrollToTop();
// });


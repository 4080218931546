let navigation;

window.onload = function () {

  /* show / hide main nav
  -------------------------------------------------- */
  const navigation = document.querySelector('.js-navigation');
  const body = document.querySelector('body');
  const menu_button = document.querySelector('.js-toggle-menu');

  menu_button.addEventListener('click', () => {
    const currentState = menu_button.getAttribute('data-state');

    if (!currentState || currentState === 'closed') {
      menu_button.setAttribute('data-state', 'opened');
      menu_button.setAttribute('aria-expanded', 'true');
      navigation.setAttribute('aria-expanded', 'true');
      body.classList.add('fixed');
    } else {
      menu_button.setAttribute('data-state', 'closed');
      menu_button.setAttribute('aria-expanded', 'false');
      navigation.setAttribute('aria-expanded', 'false');
      body.classList.remove('fixed');
    }
  });

};

export default navigation;
let contentsteps;

if (document.querySelectorAll(".contentsteps").length) {

  /* toggle mobile between steps */
  const toggler = document.getElementsByClassName('js-contentsteptoggler'); // select all trigger
  var firstToggle = toggler[0]; // select first trigger element
  var firstElement = firstToggle.nextElementSibling; // select next html element
  firstToggle.classList.add('active'); // show first step
  firstElement.classList.add('show'); // show first step

  for (const toggle of toggler) {
    toggle.addEventListener('click', function onClick() {
      if (this.classList.contains('active')) {
        this.classList.remove('active');
      } else {
        this.classList.add('active');
      }

      var next = this.nextElementSibling; // add or remove show class
      if (next.classList.contains('show')) {
        next.classList.remove('show');
      } else {
        next.classList.add('show');
      }
    });
  }

  /* toggle desktop between steps */
  const toggleLinks = document.querySelectorAll(".js-contentstepswitch"); // select all trigger
  const allDivs = document.querySelectorAll(".contentstep__item"); // select content element
  var firstToggleLink = allDivs[0]; // select first trigger element

  // Add a `click` event to each link
  for (let i = 0; i < toggleLinks.length; i++) {
    toggleLinks[i].addEventListener("click", function (e) {
      e.preventDefault(); // To avoid reloading the page

      // Make sure that ALL divs are hidden
      for (let x = 0; x < allDivs.length; x++) {
        allDivs[x].classList.remove('show');
      }

      // Show only ONE div based on the triggered link's index
      const displayDiv = allDivs[i];
      // You can display the div directly without `displayDiv` variable, but it is made for better readability only!
      displayDiv.classList.add('show');
      // Like this: allDivs[i].style.display = "block"

    });
  }

  firstToggleLink.classList.add('show'); // show first content element

}


export default contentsteps;
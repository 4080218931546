/***** rsce_cs_004 *****/
let cs004;

// encapsulate element js
cs004 = function () {
  window.addEventListener("load", function () {
    let scrollDown = document.querySelector(".clac-004-hero__scroll-down");
    if (!scrollDown) {
      return;
    }

    // add click event
    scrollDown.addEventListener("click", function () {
      let hero = document.querySelector(".clac-004-hero");
      if (!hero) {
        return;
      }

      window.scrollTo({ top: hero.scrollTop + hero.offsetHeight, behavior: "smooth" });
    });
  });
}

// init
cs004();

export default cs004;
